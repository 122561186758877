
.project-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    animation: fadein 2s;
}

#life-projects-section {
    background: linear-gradient(to top, transparent, rgb(236, 236, 236)), url("../static/img/double-bubble-outline.png");
}

#life-projects-section .project-text-container {
    background-color: #143d4c;
}

#tech-projects-section {
    background-color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

