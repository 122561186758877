.container {  display: grid;
    grid-template-columns: 2fr 0.8fr 0.2fr;
    grid-template-rows: .8fr 1.2fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    background: linear-gradient(to bottom, transparent, rgb(236, 236, 236)), url("../static/img/triangles.png");

  }

  .header { 
    grid-area: 1 / 1 / 2 / 4;
    background-color: #00B0AA;
    display: flex;
    align-items: center;
  }

  .header h1 {
    margin: .5rem 0;
}

  .intro-body { 
    grid-area: 2 / 1 / 3 / 4;
 }
 
 .photo { 
    grid-area: 1 / 2 / 4 / 3;
    background-color: #B6503B;
    align-items: center;
    display: flex;
    justify-content: center;
 }
  
  .skills { 
    grid-area: 3 / 1 / 3 / 2; 
}

@media only screen and (max-width: 750px) {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: .5fr 1fr 1fr;
    }
    .header {
        grid-area: 1 / 1;
    }
    .photo {
        grid-area: 2 / 1;
        background-color: transparent;
    }
    .intro-body {
        grid-area: 3 / 1;
    }
    .skills {
        grid-area: 4 / 1;
    }

}


#avatar {
    width: 15rem;
}


ul.skills-list {
    list-style: none;
    display:flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 0px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 9px;
}

.skills-list li {
    display: inline-block;
    text-transform: uppercase;
    min-width: 6rem;
    margin-top:8px;
}

#skills-box {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#skills-sub-box {
    padding: 0px 40px 0px 40px;
    border: 2px;
    border-style: solid;
    border-color: #143d4c;
    background-color: whitesmoke;
}

#skills-box h3 {
    background-color: #143d4c;
    color: white;
    display: block;
    text-align: center;
    margin: 0;
    width:100%;
    
 
}