@import url('https://fonts.googleapis.com/css?family=Silkscreen');
@import url('https://fonts.googleapis.com/css?family=Nunito');

h1 {
    font-family: 'Silkscreen';
    font-size: 4rem;
    text-shadow: 2px 2px 2px gray;
}

h2 {
    font-family: 'Silkscreen';
    font-size: 2.5rem;
    text-shadow: 2px 2px 2px gray;
}

h3 {
    font-size: 1.5rem;
}


body {
    font-family: 'Nunito';
    background-color: rgb(236, 236, 236);
    margin: 0;
}

#root {
  overflow: hidden;
}

.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

.text-shadow {
  text-shadow: 2px 2px 2px gray;
}

/* highlight stuff */

.highlight-container, .highlight {
    position: relative;
  }
  
.highlight-container {
    display: inline-block;
}

.highlight-container:before {
content: " ";
display: block;
height: 90%;
width: 100%;
margin-left: -3px;
margin-right: -3px;
position: absolute;
background: #00B0AA;
transform: rotate(2deg);
top: -1px;
left: -1px;
border-radius: 20% 25% 20% 24%;
padding: 10px 3px 3px 10px;
}

@media only screen and (min-width: 751px) {
  /* For larger screens: */
  .media-col-100 {
    width: 60%;
  }
  .media-col-100-small {
    width: 40%;
  }
  .padded {
    padding: 0 4rem;
  }
  h1 {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 750px) {
  /* For mobile phones: */
  .media-col-100, .media-col-100-small {
    width: 100%;
  }
  .padded {
    padding: 0 1.5rem;
  }
  h1 {
    font-size: 3rem;
    text-align: center;
  }
  
}

@media only screen and (max-width: 750px) {
  /* For super skinny mobile phones: */
  h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  
}
